import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  Modal,
  Row,
  Table,
  Typography,
  Space,
  Input,
  Select,
  DatePicker,
  Button,
} from "antd";
import {
  EyeOutlined,
  DownloadOutlined,
  SearchOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import axios from "axios";
import AppUrl from "../config/appUrl";
import "../scss/DisplayQuery.scss";
import { showNotification } from "../utils/commonFunctions";
import MyTaskFilterDate from "../components/MyTaskFilter";
import constants from "../utils/constants";
import DownloadData from "../downLoadData/DownloadData";
import moment from "moment-timezone";
import {useLocation} from "react-router-dom";
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { FILTER_TYPE_DATE } = constants;
const defaultFilterType = FILTER_TYPE_DATE?.All?._id;
const columns = [
  {
    title: "S.NO.",
    dataIndex: "key",
    width: 70,
    render: (text, record, index) => index + 1,
  },
  {
    title: "Session_id",
    dataIndex: "session_id",
    width: 200,
  },
  {
    title: "Question",
    dataIndex: "question",
    width: 600,
  },
  {
    title: "Date & Time",
    dataIndex: "dateTime",
    width: 220,
    render: (text, record) => (
      <span>{moment(record.dateTime).format("Do MMM, YYYY, h:mm A")}</span>
    ),
  },
  {
    title: "Details",
    dataIndex: "views",
    width: 80,
    render: (_, record) => <ViewModal views={record.views} />,
  },
];

const ViewModal = ({ views }) => {
  const [visible, setVisible] = useState(false);
  const handleView = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  const formattedDate = moment(views.created_at).format("Do MMM, YYYY, h:mm A");
  return (
    <div>
      <EyeOutlined
        style={{
          marginLeft: "5px",
          fontSize: "18px",
          color: "#2a2a2a",
          cursor: "pointer",
        }}
        onClick={handleView}
      />
      <Modal visible={visible} onCancel={handleCancel} footer={null}>
        <br />
        <div className={"mb-2"}>
          <label>Session_id: </label>
          <Input
            size={"large"}
            disabled={true}
            value={views._id}
            style={{ color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Question: </label>
          <Input
            size={"large"}
            disabled={true}
            value={views.input}
            style={{ color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Answer: </label>
          <Input.TextArea
            size={"large"}
            disabled={true}
            value={views.output}
            style={{ height: "150px", color: "black" }}
          />
        </div>
        <div className={"mb-2"}>
          <label>Date: </label>
          <Input
            size={"large"}
            disabled={true}
            value={formattedDate}
            style={{ color: "black" }}
          />
        </div>
        {/*<div className={"mb-2"}>
          <label>Type: </label>
          <Input size={"large"} disabled={true} value={views.type} />
        </div>*/}
      </Modal>
    </div>
  );
};

const DisplayQueryDetails = () => {
  const location = useLocation()
  console.log("--------", location?.state?.email)
  const [submitClicked, setSubmitClicked] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [states, setStates] = useState({
    search: "",
    ids: [],
    filterType: defaultFilterType,
    modelOpen: false,
    data: [],
    start_date: "",
    end_date: "",
  });
  const handleClickList = useCallback(
    (id) => {
      setStates((prevState) => ({
        ...prevState,
        filterType: id,
        modelOpen: !prevState.modelOpen,
      }));
    },
    [states.filterType]
  );

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setStates((prevState) => ({ ...prevState, [name]: value }));
    },
    []
  );

  const fetchData = () => {
    let { filterType, start_date, end_date } = states;
    let params = {
      email: location?.state?.email //|| localStorage.getItem("email"),
    };
    if (searchInput) {
      params.session_id = searchInput;
    }
    switch (filterType) {
      case "all_data":
        break;
      case "today":
        const todayStartDate = moment().startOf("day").utc().format();
        const todayEndDate = moment().endOf("day").utc().format();
        params.start_date = todayStartDate;
        params.end_date = todayEndDate;
        break;
      case "yesterday":
        const yesterdayStartDate = moment()
          .subtract(1, "day")
          .startOf("day")
          .utc()
          .format();
        const yesterdayEndDate = moment()
          .subtract(1, "day")
          .endOf("day")
          .utc()
          .format();
        params.start_date = yesterdayStartDate;
        params.end_date = yesterdayEndDate;
        break;
      case "custom_date":
        if (start_date)
        params.start_date = moment(start_date)
          .startOf("day")
          .utc()
          .format();
      if (end_date)
        params.end_date = moment(end_date).endOf("day").utc().format();
        break;
      default:
        break;
    }

    axios({ url: AppUrl.HISTORY, method: "GET", params: params })
      .then((result) => {
        if (
          result?.data?.meta?.success &&
          Array.isArray(result.data.data) &&
          result.data.data.length > 0
        ) {
          const data = result.data.data.map((o) => ({
            key: o._id,
            session_id: o._id,
            question: o.input,
            dateTime: moment(o.created_at).toDate(),
            views: o,
          }));
          setStates((prevState) => ({
            ...prevState,
            data: data,
          }));
          setFilteredData(data);
          showNotification("success", result?.data?.meta?.message);
        } else {
          setStates((prevState) => ({
            ...prevState,
            data: [],
          }));
          setFilteredData([]);
          showNotification("success", "Data not available");
        }
      })
      .catch((err) => showNotification("error", err.message));
  };

  const handleReset = () => {
    setStates({
      search: "",
      ids: [],
      filterType: defaultFilterType,
      data: [],
      start_date: "",
      end_date: "",
    });
    setSearchInput("");
    setInitialLoad(true);
  };
  useEffect(() => {
    if (initialLoad || submitClicked) {
      fetchData();
      setInitialLoad(false);
      setSubmitClicked(false);
    }
  }, [initialLoad, submitClicked]);

  const handleSubmit = () => {
    setSubmitClicked(true);
  };
  return (
    <Row className="listing-cb">
      <div style={{ width: "100%", paddingBottom:"50px" }}>
        <Row className="mt20" justify="space-between" style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={12}>
            <Space align="center">
              <Input
                id="searchInput"
                placeholder="Search by session..."
                prefix={<SearchOutlined />}
                size="large"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <MyTaskFilterDate
                handleChange={handleChange}
                state={states}
                handleClickList={handleClickList}
              />
              <Button type="primary" size="large" onClick={handleSubmit}>
                Submit
              </Button>
              <Button size="large" className="reset-btn" onClick={handleReset}>
                Reset
              </Button>
            </Space>
          </Col>

          <Col xs={24} md={12} align="end">
            <Space>
              <DownloadData data={filteredData} />
            </Space>
          </Col>
        </Row>

        <Col span={24} className="mt20">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={false}
            bordered
          />
        </Col>
      </div>
    </Row>
  );
};

export default DisplayQueryDetails;
