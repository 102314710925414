import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import moment from "moment-timezone";

const splitTextIntoLines = (text, wordsPerLine) => {
  const words = text.split(/\s+/);
  const lines = [];
  for (let i = 0; i < words.length; i += wordsPerLine) {
    lines.push(words.slice(i, i + wordsPerLine).join(" "));
  }
  return lines.join("\n");
};
let name=localStorage.getItem("name");

const DownloadData = ({ data }) => {
  console.log("data", data);
  const handleDownload = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const formattedData = data.map((item, index) => ({
      "S.NO.": index + 1,
      "SessionID": item.session_id,
      "Questions": splitTextIntoLines(item.question, 15),
      "Answer": item.views?.output
      ? item.views.output.replace(/\n/g, String.fromCharCode(10))
      : "",
      "Date": moment(item.dateTime).format("Do MMM, YYYY, h:mm A"),
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    ws["!cols"] = [
      { width: 10 },
      { width: 30 },
      { width: 50 },
      { width: 100 },
      { width: 30 },
    ];

    
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    const formattedDate = new Date().toISOString().slice(0, 10);
    const fileName = `${name} Data ${formattedDate}${fileExtension}`;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(dataBlob, fileName);
    } else {
      const dataObject = window.URL.createObjectURL(dataBlob);
      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);
      downloadLink.href = dataObject;
      downloadLink.download = fileName;
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <Button
      type="primary"
      icon={<DownloadOutlined />}
      size="large"
      onClick={handleDownload}
    >
      Download
    </Button>
  );
};

export default DownloadData;
