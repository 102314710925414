import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
    Row,
  Space,
  Input,
    Table,
    Switch,
  Button
} from "antd";

import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    DeploymentUnitOutlined,
    PlusCircleOutlined,
  RocketOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import MyTaskFilterDate from "../components/MyTaskFilter";
import constants from "../utils/constants";
import "../scss/botListing.scss";
import CardItem from "../components/cardItem/CardItem";
import AddNewBot from "../utils/AddNewBot";
import axios from "axios";
import appUrl from "../config/appUrl";
import {showNotification} from "../utils/commonFunctions";
import {useNavigate} from "react-router-dom";
import routes from "../routes_mapper";
const { FILTER_TYPE_DATE } = constants;
const defaultFilterType = FILTER_TYPE_DATE?.All?._id;

const BotListing = () => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [states, setStates] = useState({
    search: "",
    ids: [],
    filterType: defaultFilterType,
    modelOpen: false,
    data: [],
    start_date: "",
    end_date: "",
    });
    const [dashboardData, setDashboardData] = useState({})

    useEffect(() => {
        dashboardApiCall()
    }, []);

    const dashboardApiCall = ()=> {
        axios.get(appUrl.DASHBOARD+`?email=${localStorage.getItem("email")}`).then(response=>{
            setDashboardData(response?.data?.data);
            setFilteredData(response?.data?.data?.bots)
        }).catch(err=>{
            showNotification("error", err.message || "Failed to fetch dashboard")
        })
    }

    const handleClickList = useCallback(
    (id) => {
      setStates((prevState) => ({
        ...prevState,
        filterType: id,
        modelOpen: !prevState.modelOpen,
      }));
    },
    []
  );

    const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event;

      setStates((prevState) => ({ ...prevState, [name]: value }));
    },
    []
    );

    const handleInviteUserModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setShowInviteUser(show);
  }, []);

    const handleUserInvite = useCallback((payload) => {
        axios.post(appUrl.USERS, {email: localStorage.getItem("email"), ...payload}).then(response=>{
            console.log(response.data)
            setShowInviteUser(false);
            dashboardApiCall()
        }).catch(err=>{
            showNotification("error", err.message || "Failed to create bots")
        })
    },[])

    const handleUserUpdate = useCallback((payload) => {
        axios.put(appUrl.USERS+`/${payload.email}`, {active: !payload.active}).then(response=>{
            console.log(response.data)
            // setShowInviteUser(false);
            dashboardApiCall()
        }).catch(err=>{
            showNotification("error", err.message || "Failed to create bots")
        })
    },[])

    const handleUserDelete = useCallback((payload) => {
        axios.delete(appUrl.USERS+`/${payload.email}`).then(response=>{
            console.log(response.data)
            // setShowInviteUser(false);
            dashboardApiCall()
        }).catch(err=>{
            showNotification("error", err.message || "Failed to create bots")
        })
    },[])

    const columns = [
        {
            title: "S.NO.",
            dataIndex: "key",
            width: 100,
            key:"key",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Organization",
            dataIndex: "name",
            key:"name",
        },

        {
            title: "Email Address",
            dataIndex: "email",
            key:"email",
        },
        {
            title: "Launch",
            dataIndex: "launch",
            width: 120,
            key:"launch",
            render: (_, record) => <RocketOutlined onClick={()=>navigate(routes.LaunchBot, { replace: false, state: {...record} })} style={{ fontSize: "20px", color: "#2a2a2a", cursor: "pointer" }} />
        },

        {
            title: "Train",
            dataIndex: "train",
            width: 120,
            key:"train",
            render: (_, record) => <DeploymentUnitOutlined onClick={()=>navigate(routes.TrainingBot, { replace: false, state: {...record} })} style={{ fontSize: "20px", color: "#2a2a2a", cursor: "pointer" }} />
        },

        {
            title: "Query Log",
            dataIndex: "query_log",
            width: 120,
            key:"query_log",
            render: (_, record) => <QuestionCircleOutlined onClick={()=>navigate(routes.QUERYDETAILS, { replace: false, state: {...record} })} style={{ fontSize: "18px", color: "#2a2a2a", cursor: "pointer" }}/>
        },
        {
            title: "Status",
            dataIndex: "active",
            width: 120,
            key:"active",
            render: (_, record) => <Switch disabled={localStorage.getItem("email") !=="admin@procezo.com"} onChange={()=>handleUserUpdate(record)} checked={record.active} checkedChildren={<CheckOutlined style={{color: "#ffffff" }}/>} unCheckedChildren={<CloseOutlined />}
            />,
        },
        {
            title: "Action",
            dataIndex: "delete",
            width: 120,
            key:"delete",
            render: (_, record) => <DeleteOutlined onClick={()=> localStorage.getItem("email") === "admin@procezo.com" && handleUserDelete(record)} style={{ fontSize: "18px", color: "#2a2a2a", cursor: "pointer" }} />,
        },

    ];


    return (
        <Row justify="space-between" className="listing-cb">
            <div style={{width:"100%", paddingBottom:"50px"}}>
            <CardItem dashboardData={dashboardData}/>
                {localStorage.getItem("email") === "admin@procezo.com" && <Row className="mb20" justify="space-between" style={{"width":"100%", "marginTop":"30px"}}>
                <Col xs={24} sm={24} md={12}>
                    <Space align="center">
                        <Input
                        id="searchInput"
                        placeholder="Search..."
                        prefix={<SearchOutlined />}
                        size="large"

                        />
                        <MyTaskFilterDate
                        handleChange={handleChange}
                        state={states}
                        handleClickList={handleClickList}
                        />
                        <Button type="primary" size="large">
                        Submit
                        </Button>
                        <Button size="large" className="reset-btn">
                        Reset
                        </Button>
                    </Space>
                </Col>
                <Col xs={24} md={12} align="end">
                    <Space>
                        <Button type="primary" icon={<PlusCircleOutlined />} size="large" onClick={() => handleInviteUserModal(true)}>{" "} Add New Bot</Button>
                    </Space>
                </Col>
            </Row>}
            <Col span={24}>
                <Table columns={columns} dataSource={filteredData} pagination={false} bordered rowKey="_id" />
            </Col>

            <AddNewBot
                visible={showInviteUser}
                handleModal={handleInviteUserModal}
                onUserInvite={handleUserInvite}
                />
           </div>
        </Row>
    );
};

export default BotListing;
