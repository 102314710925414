import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Input, Row, Col } from "antd";

import CustomModal from "../components/CustomModal";

const AddNewBot = (props) => {
  const { visible, handleModal, onUserInvite } = props;
  const [state, setState] = useState({});
  const [formError, setFormError] = useState({
        first_name: null,
        last_name: null,
        email: null,
      });

  const hasError = useCallback(() => {
    let { organizationName, userName } = state;
    let error = {};

    organizationName = organizationName?.trim();
    if (!organizationName) {
      error.organizationName = "Please enter organization name";
    }
    if (!userName) {
      error.userName = "Please enter user name";
    }
    if (!passwordName) {
      error.passwordName = "Please enter password";
    }


    setFormError(error);
    return !!Object.keys(error).length;
  }, [state]);

  const handleChange = useCallback(
    (name) => (event) => {
      let value = event?.target?.value ?? event?.target?.checked;

      setFormError({});
      setState((pre) => ({
        ...pre,
        [name]: value,
      }));
    },
    []
  );
    let { passwordName,organizationName, userName } = state;

 const onOk = useCallback(() => {
    if (!hasError()) {
        setState((pre) => ({
            ...pre,
            loading: true,
        }))
      let newUser = {
        name: organizationName,
        username: userName,
        password: passwordName,
      };
        onUserInvite(newUser)
        setState({})
    }
  }, [passwordName,organizationName, hasError, userName]);



  const renderFooter = useMemo(() => {
    return (
      <Row className="p10">
        <Button
          className="new_button"
          type="primary"
                size="large"
                 onClick={onOk}
          loading={state.loading}

        >
          Add
        </Button>
        <Button
          className="new_button"
          type="primary-outline"
          onClick={handleModal}
          size="large"
        >
          Cancel
        </Button>
      </Row>
    );
  }, [handleModal, onOk]);

  return (
    <CustomModal
      visible={visible}
      title={"Add New Bot"}
      okButtonText={"Submit"}
      onCancel={props?.handleModal}
      footer={renderFooter}
    >
      <div>
        <Col className="mb10">
          <Col className="mb5">
            <label className="label">Organization</label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={organizationName}
              placeholder=""
              onChange={handleChange("organizationName")}
            />
            <Row className="error mt5">{formError?.organizationName}</Row>
          </Col>
        </Col>

        <Col className="mb10">
          <Col className="mb5">
                      <label className="label">Username</label>
                      <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
              size="large"
              type="text"
              value={userName}
              placeholder=""
              onChange={handleChange("userName")}
                      />
                      <Row className="error mt5">{formError?.userName}</Row>
          </Col>
        </Col>

        <Col>
          <Col className="mb5">
            <label className="label">Password </label>
            <sup style={{ color: "red" }}>*</sup>
          </Col>
          <Col>
            <Input
            placeholder="**********"
            type="password"
              value={passwordName}
              onChange={handleChange("passwordName")}
              size="large"
              allowClear
            />
            <Row className="error mt5">{formError?.passwordName}</Row>
          </Col>
        </Col>
      </div>
    </CustomModal>
  );
};

export default AddNewBot;
