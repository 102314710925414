import React from "react";
import { Button, Popover,DatePicker } from "antd";
import { disabledFutureDate } from "../utils/commonFunctions";
import { FilterOutlined } from "@ant-design/icons";
import constants from "../utils/constants";
import moment from "moment-timezone";
const { FILTER_TYPE_DATE } = constants;

const MyTaskFilterDate = (props) => {
  const { handleChange = () => {}, state = {}, handleClickList } = props;

  return (
    <Popover
      getPopupContainer={(triggerNode) => triggerNode}
      bordered={false}
      title={null}
      trigger="click"
      placement="bottom"
      content={
        <div style={{ display: "flex", gap: 15 }}>
          <ul className="list-item-filter">
            {Object.values(FILTER_TYPE_DATE)?.map((filter) => (
              <li
                key={filter?._id}
                data-value={filter?._id}
                onClick={() => handleClickList(filter?._id)}
                className={filter?._id === state?.filterType ? "active" : ""}
              >
                <span>{filter?.name}</span>
              </li>
            ))}
          </ul>

          {state?.filterType === FILTER_TYPE_DATE?.CUSTOM_DATE?._id && (
            <div style={{ display: "flex", alignItems: "flex-start", gap: "15px" }}>
              <DatePicker.RangePicker
                style={{ width: 340 }}
                onChange={(date, dateString) => {
                  handleChange("start_date")(dateString[0]);
                  handleChange("end_date")(dateString[1]);
                }}
                placeholder={["From", "To"]}
                value={[
                  state?.start_date ? moment(state?.start_date) : null,
                  state?.end_date ? moment(state?.end_date) : null,
                ]}
                disabledDate={disabledFutureDate}
                inputReadOnly={true}
                allowClear
              />
            </div>
          )}
        </div>
      }
    >
      <Button
        className="new_button fw"
        icon={<FilterOutlined />}
        type="primary"
        size="large"
      >
        <span className="f15">
          {state?.filterType &&
            Object.values(FILTER_TYPE_DATE)?.find(
              (filter) => filter?._id === state?.filterType
            )?.name}
        </span>
      </Button>
    </Popover>
  );
};


export default MyTaskFilterDate;
