import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  Col,
  Row,
  Space,
  Input,
  Table,
  Switch,
  Button,
  Upload,
  Avatar,
  Empty,
} from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { showNotification } from "../utils/commonFunctions";
import {
  UploadOutlined,
  DeploymentUnitOutlined,
  DeliveredProcedureOutlined,
  SaveOutlined,
  SendOutlined,
  QuestionCircleOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import axios from "axios";
import AppUrl from "../config/appUrl";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import SetTrainingPromptModal from "./SetTrainingPromptModal";
import { useLocation } from "react-router-dom";
import appUrl from "../config/appUrl";
import moment from "moment-timezone";
import SetMessageModal from "./SetMessageModel";

const TrainingBot = () => {
  const location = useLocation();
  // console.log("--------", location?.state)
  const [state, setState] = useState({
    message: "",
    text: "",
    showDefaultResponse: false,
  });
  const [open, setOpen] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileUrlList, setFileUrlList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [textFromFiles, setTextFromFiles] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [hideChatInput, setHideChatInput] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const messageRefs = useRef([]);
  const [toggleState, setToggleState] = useState(1);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const email = location?.state?.email;

    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message", res.data?.data?.error_message);
          // localStorage.setItem("prompt",res.data?.data?.prompt)
          // if(localStorage.getItem("name") !== res.data?.data?.name){
          //   localStorage.setItem("name", res.data?.data?.name)
          // }
          const files = res.data?.data?.files || [];
          setFileUrlList(files);
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message,
            prompt: res.data?.data?.prompt,
            custom_message: res.data?.data?.error_message,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fetch file data");
      });
  };

  const saveData = (customMessage, type) => {
    console.log(fileList, customMessage, type);
    setState((preState) => ({
      ...preState,
      loading: true,
      val: "",
      type: "",
    }));

    if (fileUrlList.length > 0) {
      //   let formData = new FormData();
      //   fileList.map((o, i)=>formData.append("files", o))
      //   axios.post(appUrl.UPLOAD, formData).then(response=>{
      //     const file = response?.data?.data?.urls || [];
      //     console.log("Files",file)
      saveText(customMessage, type, fileUrlList);
      // }).catch(err=>showNotification("error", err.message || "Failed to upload file"))
    } else {
      saveText(customMessage, type);
    }
  };

  const saveText = (customMessage, type, file) => {
    const email = location?.state?.email;

    let payload = { email: email };
    if (state.text) {
      payload.text = state.text.trim();
    }
    if (type === "custom_message" && typeof customMessage === "string") {
      payload = { email: email, customMessage: customMessage };
    }
    if (type === "prompt") {
      payload.prompt = customMessage;
      payload.text = undefined;
    }
    if (file) {
      payload.files = file;
    }

    axios
      .post(AppUrl.SAVE_FILE, payload)
      .then((res) => {
        if (res.data.success) {
          setState((preState) => ({
            ...preState,
            loading: false,
            val: "",
            type: "",
          }));
          getData();
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to save file data");
      });
  };

  const beforeUpload = useCallback((file) => {
    const supportedFileType = ["text/plain"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .txt file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    setState((prevState) => ({ ...prevState, loading: true }));
    let formData = new FormData();
    formData.append("files", file);
    axios
      .post(appUrl.UPLOAD, formData)
      .then((response) => {
        const file = response?.data?.data?.urls || [];
        console.log("Files", file);
        const files = file.map((o) => {
          return {
            uid: file.uid,
            name: o.split("%40")[1],
            url: o,
            mimeType: o.includes(".pdf") ? "application/pdf" : "text/plain",
            extension: o.includes(".pdf") ? ".pdf" : ".txt",
          };
        });
        console.log("---->", fileUrlList, files);
        setFileUrlList((prevState) => [...prevState, ...files]);
        setState((prevState) => ({ ...prevState, loading: false }));
        // saveText(customMessage, type, file)
      })
      .catch((err) =>
        showNotification("error", err.message || "Failed to upload file")
      );
    // const fileId = file?._id || file?.uid;
    // if (file.type === "text/plain") {
    //   const reader = new FileReader();
    //   console.log(file)
    //   reader.onload = (e) => {
    //     const fileContent = e.target.result;
    //     console.log(fileContent)
    //     setTextFromFiles((preTextFile) => ({
    //       ...preTextFile,
    //       [fileId]: fileContent,
    //     }));
    //
    //   };
    //   reader.readAsText(file);
    // }
    // setFileList((preState) => [...preState, file]);
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;
    //
    // setTextFromFiles((preFile) => {
    //   delete preFile[fileId];
    //   return preFile;
    // });

    setFileUrlList((preState) =>
      preState.filter((p) => (p?.uid || p?._id) !== fileId)
    );
  }, []);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  const onSend = useCallback(() => {
    let message = state.message.trim();
    const text = state.text.trim();

    /* if (!message) return;
    if (!text) return; */

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    // const custom_message = localStorage.getItem("custom_message");

    const payload = {
      question: message,
      // text: text,
      email: location?.state?.email,
      // customMessage: custom_message,
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.FILES}custom`,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        // console.log(resData);

        if (!resData?.text) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = {};
          let content = resData?.text;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();

            return [...prevMessages, newMessage];
          });
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages]);

  const handleUserModal = useCallback((show = false) => {
    show = typeof show === "boolean" && show;
    setOpenPrompt(show);
    setState((prev) => ({ ...prev, type: "", val: "" }));
  }, []);

  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  const deletePrompt = (val) => {
    let prompt = state.prompt;
    prompt = state.prompt.filter((o) => o.value !== val.value);
    saveData(prompt, "prompt");
    showNotification("success", "Successfully data deleted!");
  };
  const editPrompt = (val) => {
    setOpenPrompt(true);
    setState((prev) => ({ ...prev, type: "edit", val: val }));
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : "";

  return (
    <>
      <Row justify="space-between" className="listing-cb">
        <div style={{ width: "100%", paddingBottom: "50px" }}>
          <Col xs={24} className="bot-training">
            <div className="tabs">
              <div
                className={`tab-label ${getActiveClass(1, "active-tabs")}`}
                onClick={() => toggleTab(1)}
              >
                <DeploymentUnitOutlined style={{ marginRight: "3px" }} />{" "}
                Training Data
              </div>
              <div className={`panel ${getActiveClass(1, "active-content")}`}>
                <Row
                  className="mb15"
                  justify={"space-between"}
                  style={{ flexFlow: "initial" }}
                >
                  {/* <input type="file" onChange={handleFileUpload} /> */}
                  <Col style={{ flex: "1 0 0%", paddingRight: "30px" }}>
                    <Upload
                      className="upload-list-inline"
                      showUploadList={true}
                      accept={".txt"}
                      beforeUpload={beforeUpload}
                      listType="picture"
                      multiple={true}
                      fileList={fileUrlList}
                      onRemove={onRemoveImage}
                      customRequest={updateImageUpload}
                      disabled={state.loading}
                    >
                      <Button
                        type="primary"
                        loading={state?.loading}
                        icon={<UploadOutlined />}
                        size="large"
                        style={{ minWidth: "160px" }}
                      >
                        {" "}
                        Upload File
                      </Button>
                    </Upload>
                  </Col>
                  <Col style={{ flex: "0 0 auto", width: "auto" }}>
                    <Button
                      type="primary"
                      icon={<SaveOutlined />}
                      size="large"
                      style={{ minWidth: "160px" }}
                      onClick={saveData}
                      disabled={state.loading}
                    >
                      {" "}
                      Save Data
                    </Button>
                  </Col>
                </Row>

                <Row>
                  <Col className="--right-container" xs={24} lg={16}>
                    <Col className="sticky h-full top-0">
                      <Col className="">
                        <Row className=" h-full">
                          {/* <Input.TextArea
                      className=" input-text-editor grow-1"
                      value={state.text}
                      placeholder="Provide text or knowledge base here to train your  model."
                      onChange={handleChange("text")}
                      //   autosize={{ minRows: 15, maxRows: 19 }}
                      onPressEnter={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          handleChange("text");
                        }
                      }}
                      disabled={state.loading}
                      size="large"
                    /> */}

                          <ReactQuill
                            style={{
                              height: "560px",
                              marginBottom: "50px",
                              width: "100%",
                            }}
                            placeholder={
                              "Provide text or knowledge base here to train your  model."
                            }
                            value={state.text}
                            disabled={state.loading}
                            onChange={handleChange("text")}
                            theme="snow"
                          />
                        </Row>
                      </Col>
                    </Col>
                  </Col>

                  <Col xs={24} lg={8}>
                    <div className="bot-cb-main">
                      <div className="center-frame">
                        <div className="tab-frame-cbs">
                          <div className="tab-inner-frame-cb">
                            <div className="tab-inner-frame-top">
                              <Col
                                className=""
                                style={{
                                  height: "518px",
                                  overflowY: "auto",
                                  paddingRight: "5px",
                                  paddingTop: "10px",
                                }}
                              >
                                {/* chat container start */}
                                <Col className="chat-container h-4/6">
                                  <Col className="message-list-container">
                                    <Row className="chat-item-container">
                                      <Col>
                                        <Avatar
                                          size={40}
                                          shape={"circle"}
                                          style={{
                                            backgroundColor:
                                              "rgb(255, 161, 108)",
                                          }}
                                        >
                                          {"Bot"}
                                        </Avatar>
                                      </Col>

                                      <Col className="chat-details-container">
                                        <Row
                                          className="user-details"
                                          align="middle"
                                          justify="space-between"
                                        >
                                          {/* <span className="name">{createdBy.name ?? "Bot"}</span> */}
                                          <span className="name">
                                            {location?.state?.name}
                                          </span>
                                          <span>
                                            Hello 👋, I'm the{" "}
                                            {location?.state?.name} bot, ready
                                            to help! 😊 Feel free to share your
                                            inquiry with me.
                                          </span>
                                        </Row>
                                      </Col>
                                    </Row>
                                    {/* <div className="defaultMsg">
                          <p>
                            Hello 👋, I'm the Fit2Trip bot, ready to help! 😊
                          </p>
                          <p>Feel free to share your inquiry with me. </p>
                        </div> */}
                                    {messages?.map?.((message, i) => (
                                      <div
                                        key={i}
                                        ref={(mRef) =>
                                          (messageRefs.current[i] = mRef)
                                        }
                                      >
                                        <OpenAiChatItem
                                          key={i}
                                          state={location?.state}
                                          message={message}
                                        />
                                        <Row justify="end" align="">
                                          {message.content ===
                                            "Sorry we could not find detail about your query. Please contact our support team at help@oben.com | +91 1232123212." && (
                                            <Col
                                              style={{
                                                marginRight: "20px",
                                                marginTop: "-110px",
                                              }}
                                            >
                                              {/* <Button>
                                    <MailOutlined onClick={handleSendEmail} />
                                  </Button> */}
                                            </Col>
                                          )}
                                        </Row>
                                      </div>
                                    ))}

                                    {/* {!messages.length && (
                          <Row
                            className="h-full"
                            align="middle"
                            justify="center"
                          >
                            <div className="defaultMsg">
                              <p>
                                Hello 👋, I'm the Fit2Trip bot, ready to help!
                                😊
                              </p>
                              <p>Feel free to share your inquiry with me. </p>
                            </div>
                          </Row>
                        )} */}
                                  </Col>
                                </Col>
                                {/* chat container end */}
                              </Col>

                              <Col className="mt20">
                                {/* chat input container end */}
                                <Row align="middle" wrap={false}>
                                  <Col className="grow">
                                    <Input
                                      className="chat-input"
                                      value={state.message}
                                      placeholder="Enter your prompt"
                                      onChange={handleChange("message")}
                                      autosize={{ minRows: 1, maxRows: 4 }}
                                      onPressEnter={(e) => {
                                        if (e.key === "Enter" && !e.shiftKey) {
                                          onSend();
                                        }
                                      }}
                                      disabled={state.loading}
                                      size="large"
                                    />
                                  </Col>
                                  <Button
                                    className="text-2xl btn-color"
                                    type="text"
                                    onClick={onSend}
                                    disabled={!state.message.trim()}
                                    loading={state.loading}
                                    size="large"
                                    icon={<SendOutlined className="text-3xl" />}
                                  />
                                </Row>
                                {/* chat input container end */}
                              </Col>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div
                className={`tab-label ${getActiveClass(2, "active-tabs")}`}
                onClick={() => toggleTab(2)}
              >
                <DeliveredProcedureOutlined style={{ marginRight: "3px" }} />{" "}
                Prompt Template
              </div>
              <div className={`panel ${getActiveClass(2, "active-content")}`}>
                <Row className="mb15" style={{ flexFlow: "initial" }}>
                  <Col xs={24} align="end">
                    <Row>
                      <Col xs={24} align="end">
                        <Space>
                          <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            size="large"
                            loading={state?.loading}
                            style={{ minWidth: "160px" }}
                            onClick={() => handleUserModal(true)}
                          >
                            {" "}
                            Add Prompt
                          </Button>

                          <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            size="large"
                            loading={state?.loading}
                            style={{ minWidth: "160px" }}
                            onClick={() => setOpenMessage(true)}
                          >
                            {" "}
                            Add Custom Message
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {state?.prompt && state?.prompt.length === 0 && (
                  <div className="empty-container">
                    <Empty />
                  </div>
                )}

                {state?.prompt &&
                  state?.prompt.length !== 0 &&
                  state?.prompt.map((o) => {
                    return (
                      <Row key={o.date} className="task-row-item-o-cb">
                        <Col xs={24}>
                          <div className="task-row-item-card">
                            <div className="task-content">{o.value}</div>

                            <div className="task-icons">
                              <Row>
                                <Space>
                                  <div className="task-created">
                                    <span>
                                      Created on{" "}
                                      {moment.utc(o.date).format("DD MMM YYYY")}
                                    </span>
                                  </div>
                                  <EditOutlined
                                    onClick={() => editPrompt(o)}
                                    style={{
                                      fontSize: "16px",
                                      color: "#2a2a2a",
                                      cursor: "pointer",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <DeleteOutlined
                                    onClick={() => deletePrompt(o)}
                                    style={{
                                      fontSize: "16px",
                                      color: "#2a2a2a",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Space>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            </div>
          </Col>
        </div>
      </Row>
      <SetMessageModal
        open={openMessage}
        handleModal={setOpenMessage}
        save={saveData}
      />

      <SetTrainingPromptModal
        val={state?.val}
        type={state?.type}
        state={state}
        open={openPrompt}
        handleModal={handleUserModal}
        save={saveData}
      />
    </>
  );
};

export default TrainingBot;
