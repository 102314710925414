import React from "react";
import { Layout, Menu, Row } from "antd";
import { useLocation } from "react-router-dom";
import appLargeLogo from "../images/artificial-intelligence.png";
import useSidebarMenuItems from "./useSidebarMenuItems";

import "./AppSidebar.scss";

const AppSidebar = (props) => {
  const location = useLocation();
  //const isCustomBotRoute = location.pathname === '/custom-bot';
   const isCustomBotRoute = location.pathname === '/custom-bot' || location.pathname === '/custom-bot-go' || location.pathname === '/custom-bot-citiustech';
  const appMenuItems = useSidebarMenuItems();
  if (isCustomBotRoute) {
    return null;
  }
  return (
    <Layout.Sider
      className="app-sidebar"
      width={240}
      trigger={null}
      collapsible
      collapsed={props?.isSidebarCollapsed}
      theme="dark"
      breakpoint="md"
      onBreakpoint={(broken) => {
        if (broken && !props?.isSidebarCollapsed) props?.toggleSidebar();
      }}
    >
      <Row className="logo-container">
        {!props?.isSidebarCollapsed ? (
          <div className="logo">
            <img
              style={{ height: 60, width: 60 }}
              src={appLargeLogo}
              alt="app logo"
            />
          </div>
        ) : (
          <div className="logo">
            <img
              style={{ height: 50, width: 50 }}
              src={appLargeLogo}
              alt="app logo"
            />
          </div>
        )}
      </Row>

      <Menu
        theme="dark"
        mode="inline"
        className="app-sidebar-menu"
        selectedKeys={[`${location?.pathname}`]}
        items={appMenuItems}
      />
    </Layout.Sider>
  );
};

export default React.memo(AppSidebar);
