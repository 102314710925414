import React, { useState } from "react";
import { Col, Row } from "antd";
import citiustechLogo from "../images/citiustech-logo.svg";

const ChatFormBox = ({setShowForm, setShowThankMsg}) => {
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
    info: "",
  });

  // State to manage form errors
  const [errors, setErrors] = useState({});

  // Input change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Form validation function
  const validateField = (name, value) => {
    let error = "";
    if (name === "name" && !value.trim()) {
      error = "Name is required";
    }
    if (name === "email") {
      if (!value.trim()) {
        error = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        error = "Email is invalid";
      }
    }
    if (name === "organization" && !value.trim()) {
      error = "Organization is required";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // Validate all fields at once (for form submit)
  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email is invalid";
    }
    if (!formData.organization.trim()) {
      formErrors.organization = "Organization is required";
    }

    return formErrors;
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully", formData);
      setShowForm(false);
      setShowThankMsg(true)
      // Perform further actions (e.g., send data to server)
    } else {
      console.log("Form has errors");
    }
  };

  // Handle onBlur event for real-time validation
  const handleBlur = (e) => {
    const { name, value } = e.target;
    validateField(name, value);
  };

  return (
    <Col className="open-ai-chat-item mb-2">
      <Row className="chat-item-container">
        <Col style={{ width: "34px" }}>
          {/* <img src={citiustechLogo} alt="User" style={{ width: "34px" }} /> */}
        </Col>

        <Col className="chat-details-container">
          <form onSubmit={handleSubmit}>
            <Col className="chat-details-data-form">
              <Col>
                <h4>Please share the following details</h4>

                <div className="form-field-cb">
                  <input
                    className={`c-chat-input ${errors.name ? "error":""}`}
                    name="name"
                    placeholder="Enter your name*"
                    value={formData.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.name && (
                    <p className="error-message">{errors.name}</p>
                  )} */}
                </div>

                <div className="form-field-cb">
                  <input
                    className={`c-chat-input ${errors.email ? "error":""}`}
                    name="email"
                    placeholder="Enter your email*"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.email && (
                    <p className="error-message">{errors.email}</p>
                  )} */}
                </div>

                <div className="form-field-cb">
                  <input
                    className={`c-chat-input ${errors.organization ? "error":""}`}
                    name="organization"
                    placeholder="Company/Organization*"
                    value={formData.organization}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {/* {errors.organization && (
                    <p className="error-message">{errors.organization}</p>
                  )} */}
                </div>

                <div className="form-field-cb">
                  <textarea
                    className="c-chat-input"
                    name="info"
                    placeholder="Is there specific information you would like to share?"
                    value={formData.info}
                    onChange={handleChange}
                    style={{ height: "60px" }}
                  />
                </div>

                <div className="form-field-cb">
                  <p>
                    *By submitting your personal information to CitiusTech, you
                    are agreeing to CitiusTech's{" "}
                    <a
                      href="index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>{" "}
                    on how your information may be used.
                  </p>
                </div>

                <div className="chat-c-cb-m form-field-cb">
                  <button type="submit" className="custom-button-container">
                    Submit
                  </button>
                </div>
              </Col>
            </Col>
          </form>
        </Col>
      </Row>
    </Col>
  );
};

export default React.memo(ChatFormBox);
