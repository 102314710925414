import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Divider, Input, Row, Upload } from "antd";
import {
  LeftOutlined,
  SendOutlined,
  UploadOutlined,
  SaveOutlined,
  InboxOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import { showNotification } from "../utils/commonFunctions";
import GenerateContentContainer from "../openAiChat/components/GenerateContentContainer";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import SetMessageModal from "./SetMessageModal";
import Papa from "papaparse";
const { Dragger } = Upload;


const MultipleFileUpload = () => {
  const messageRefs = useRef([]);
  const navigate = useNavigate();

  const [state, setState] = useState({
    message: "",
    text: "",
  });
  const [open, setOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [messages, setMessages] = useState([]);
  const [CSVData, setCSVData] = useState();
  const [textFromFiles, setTextFromFiles] = useState({});

  useEffect(() => {
    // Your code here
    const email = localStorage.getItem("email");
    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message",res.data?.data?.error_message)
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fatch file data");
      });
  }, []);
  const saveData = (customMessage) => {
    // const email = localStorage.getItem("email");
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    console.log('---++++', CSVData)
    axios({
      method: "POST",
      url: `${AppUrl.UPLOAD_FILE}`,
      data: CSVData,

      headers: {
        "Content-Type": "application/json",
        Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    }).then(res=>{
      console.log('---++++', CSVData)
      showNotification("success", res.data?.message)
      setState((preState) => ({
        ...preState,
        loading: false,
      }));
    }).catch(err=>showNotification("error", "Failed to uploaded"))

      //   }
      // })
      // .catch((err) => {
      //   console.log(err);
      //   showNotification("error", "Failed to save file data");
      // });
  };

  const beforeUpload = useCallback((file) => {
    console.log(file?.type)
    const supportedFileType = ["text/csv"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .csv file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    if (file.type === "text/csv") {
      let commonConfig = { delimiter: ",", skipEmptyLines: true };
      Papa.parse(
          file,
          {
            ...commonConfig,
            header: true,
            complete: (result) => {
              console.log("___",result.data)
              setCSVData(result.data);
            }
          }
      );
      showNotification("success","Successfully uploaded")
      // console.log(file)
      // const data = new FormData();
      // data.append("file", file)
      // axios({
      //   method: "POST",
      //   url: `${AppUrl.UPLOAD_FILE}`,
      //   data: data,
      //
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization:
      //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      //   },
      // }).then(res=>showNotification("success", res.data?.message)).catch(err=>showNotification("error", "Failed to uploaded"))
    }
    setFileList((preState) => [...preState, file]);
    setState((preState) => ({
      ...preState,
      loading: false,
    }));
  }, []);

  const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setTextFromFiles((preFile) => {
      delete preFile[fileId];
      return preFile;
    });

    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []);

  useEffect(() => {
    let text = Object.values(textFromFiles).join("\n");
    setState((preState) => ({
      ...preState,
      text,
    }));
  }, [textFromFiles, fileList.length]);

  const onGoBack = useCallback(() => navigate(-1), [navigate]);

  const onSend = useCallback(() => {
    let message = state.message.trim();
    const text = state.text.trim();
    //
    if (!message) return;
    // if (!text) return;

    const newMessages = messages;
    newMessages.push({
      role: "user",
      content: message,
    });
    const custom_message = localStorage.getItem("custom_message");

    const payload = {
      question: message,
      // text: text,
      email: localStorage.getItem("email"),
      // customMessage: custom_message
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: AppUrl.GET_JSON,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        console.log('------>',resData);

        if (!resData) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          // resData.email = localStorage.getItem("email");
          // axios({
          //   url:`${AppUrl.CUSTOM_FETCH}`,
          //   method: "POST",
          //   data: resData,
          //   headers: {
          //     "Content-Type": "application/json",
          //     Authorization:
          //         "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
          //   },
          // }).then((response) => {
          //   const resData = response.data;
          //   console.log(resData);
            // let text = resData?.products?.map(o=>"Product Name: " + o["Product Name"] +  "\n").join();

            const newMessage = {};
            let content = resData?.text || resData?.products;
            // while (content.startsWith("\n")) {
            //   content = content.replace("\n", "");
            // }
            newMessage.content = content;
            //
            setTimeout(
              () =>
                messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                  behavior: "smooth",
                }),
              100
            );
            //
            setMessages((prevMessages) => {
              prevMessages.pop();

              return [...prevMessages, newMessage];
            });
            setState((prevState) => ({ ...prevState, loading: false }));
          // }).catch((err) => {
          //   showNotification("error", "Something went wrong");
          //   setMessages((prevMessages) => {
          //     const lastMesssage = prevMessages.pop();
          //     console.log("remove message: ", lastMesssage);
          //
          //     return [...prevMessages];
          //   });
          //   setState((prevState) => ({...prevState, loading: false}));
          // });






        }

      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  return (
    <>
      <GenerateContentContainer containerClassName="open-ai-chat">
        <Row className="content-container">
          <Row className="fw h-full" justify="space-between">
            <Col
              className="--left-container"
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              xxl={11}
            >
              <Col className="title-container mb15">
                <Row align="middle">
                  <LeftOutlined className="text-xl mr-2" onClick={onGoBack} />

                  <Col className="title">Fine-tune ChatGPT via File Upload</Col>
                </Row>
              </Col>

              <Col className="" style={{ height: "60vh", overflowY: "auto" }}>
                {/* chat container start */}
                <Col className="chat-container h-4/6">
                  <Col className="message-list-container">
                    {messages?.map?.((message, i) => (
                      <div
                        key={i}
                        ref={(mRef) => (messageRefs.current[i] = mRef)}
                      >
                        <OpenAiChatItem key={i} message={message} />
                      </div>
                    ))}

                    {!messages.length && (
                      <Row className="h-full" align="middle" justify="center">
                        <img
                          className="h-64 w-full"
                          src="/img/empty-ai-chat.svg"
                          alt="Writely Bot"
                        />
                      </Row>
                    )}
                  </Col>
                </Col>
                {/* chat container end */}
              </Col>

              <Col className="mt20">
                {/* chat input container end */}
                <Row align="middle" wrap={false}>
                  <Col className="grow">
                    <Input.TextArea
                      className="chat-input"
                      value={state.message}
                      placeholder="Enter your prompt"
                      onChange={handleChange("message")}
                      autoSize={{ minRows: 1, maxRows: 4 }}
                      onPressEnter={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          onSend();
                        }
                      }}
                      disabled={state.loading}
                      size="large"
                    />
                  </Col>
                  <Button
                    className="text-2xl"
                    type="text"
                    onClick={onSend}
                    // disabled={!state.message.trim()}
                    loading={state.loading}
                    size="large"
                    icon={<SendOutlined className="text-3xl" />}
                  />
                </Row>
                {/* chat input container end */}
              </Col>
            </Col>

            <Col
              xs={0}
              sm={0}
              md={0}
              lg={1}
              xl={1}
              xxl={1}
              className="h-5/6"
              align="center"
            >
              <Divider type="vertical" />
            </Col>

            <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} align="center">
              <Divider />
            </Col>

            <Col
              className="--right-container"
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={11}
              xxl={11}
            >
              <Row className="mb15" justify={"space-between"}>
                {/* <input type="file" onChange={handleFileUpload} /> */}


                {/*<Col>*/}
                  <Button
                    className="mr10"
                    type="primary"
                    onClick={() => setOpen(true)}
                  >
                    Custom Message
                  </Button>
                  <Button
                    onClick={saveData}
                    disabled={state.loading}
                    icon={<SaveOutlined />}
                  >
                    Save Data
                  </Button>
                {/*</Col>*/}
              </Row>

              <Col className="sticky h-full top-0">
                <Col className="h-5/6 mb-16">
                  <Row className=" h-full">
                    <Dragger
                        customRequest={updateImageUpload}
                        // className="upload-list-inline"
                        showUploadList={true}
                        // accept={".txt"}
                        beforeUpload={beforeUpload}
                        listType="picture"
                        multiple={false}
                        fileList={fileList}
                        onRemove={onRemoveImage}
                        disabled={state.loading}
                    >
                      {/*<Button icon={<UploadOutlined />}>Upload File</Button>*/}
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                      </p>
                    </Dragger>
              {/*      <Input.TextArea*/}
              {/*        className=" input-text-editor grow-1"*/}
              {/*        value={state.text}*/}
              {/*        placeholder="Provide text or knowledge base here to train your  model."*/}
              {/*        onChange={handleChange("text")}*/}
              {/*        //   autoSize={{ minRows: 15, maxRows: 19 }}*/}
              {/*        onPressEnter={(e) => {*/}
              {/*          if (e.key === "Enter" && !e.shiftKey) {*/}
              {/*            handleChange("text");*/}
              {/*          }*/}
              {/*        }}*/}
              {/*        disabled={state.loading}*/}
              {/*        size="large"*/}
              {/*      />*/}
                  </Row>
                </Col>
              </Col>
            </Col>
          </Row>
        </Row>
      </GenerateContentContainer>
      <SetMessageModal open={open} handleModal={setOpen} save={saveData} />
    </>
  );
};

export default React.memo(MultipleFileUpload);
