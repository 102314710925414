import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";

const sagaMiddleware = createSagaMiddleware({
  onError: (err) => {
    console.error("CreateSagaMiddleware", err);
    alert("There is some problem. Please check console");
  },
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    });
    defaultMiddleware.push(sagaMiddleware);

    return defaultMiddleware;
  },
});

sagaMiddleware.run(rootSaga);

export default store;
