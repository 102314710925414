import { put, takeLatest, call } from "redux-saga/effects";
import axios from "axios";
import { loggedInUserActions } from "./loggedInUserReducer";
import { showNotification } from "../../utils/commonFunctions";
import appUrl from "../../config/appUrl";
import constants from "../../utils/constants";

const {
  loginRequest,
  onGetUserDetailsRequest,
  onHandleSuccess,
  onHandleError,
} = loggedInUserActions;

// login user start
const loginApi = (data) => {
  return axios({
    method: "POST",
    url: appUrl.LOGIN_URL,
    data,
  });
};

function* login(action) {
  try {
    const response = yield call(loginApi, action.payload);
    const data = response.data;
    console.log('----------->',data)
    if (data?.success !== true) {
      showNotification("error", data?.message);
      yield put(onHandleError(data));
    } else {

      const { email, name } = data.data;

      localStorage.setItem("email", email);
      localStorage.setItem("name", name);
      yield put(onHandleSuccess(data.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("login ", error);
    yield put(onHandleError(error));
  }
}

// login user end

// get users details end
const getUserDetailsApi = () => {
  return axios({
    method: "GET",
    url: `${appUrl.USERS}/getCurrentUser`,
  });
};

function* getUserDetails(action) {
  try {
    const response = yield call(getUserDetailsApi);
    const data = response.data;

    const meta = data?.meta;
    if (meta?.success !== true || meta?.status !== 200) {
      showNotification("error", meta?.message);
      yield put(onHandleError(data));
    } else {
      yield put(onHandleSuccess(data?.data));
    }
  } catch (error) {
    showNotification("error", "Something went wrong");
    console.log("getUserDetails ", error);
    yield put(onHandleError(error));
  }
}
// get users details end

function* loggedInUserSaga() {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(onGetUserDetailsRequest.type, getUserDetails);
}

export default loggedInUserSaga;
