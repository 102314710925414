import { Button, Col, Input, Modal, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomModal from "../components/CustomModal";
import moment from "moment-timezone";
import { showNotification } from "../utils/commonFunctions";

const SetTrainingPromptModal = ({
  open,
  handleModal,
  save,
  state,
  type,
  val
}) => {
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const prevProp = useRef();

  useEffect(() => {
    if (!prevProp?.current?.open && open) {
      const prompt = state?.prompt ?? [];
      setMessageList(prompt);
    }
    if (type === "edit") {
      setMessage(val?.value);
    }

    return () => {
      prevProp.current = { open };
    };
  }, [open]);

  const handleChange = useCallback((e) => {
    let value = e?.target?.value ?? e;
    setMessage(value);
  }, []);

  const handleOk = useCallback(() => {
    // localStorage.setItem("prompt", message);
    handleModal(false);
    const data = { value: message, date: moment.utc().toDate() };
    save(messageList.concat([data]), "prompt");
    console.log("Message 1", message)
    setMessage("");
    showNotification("success", "Successfully add new data!");
  }, [handleModal, messageList, message]);

  console.log("Message 2", message)

  const handleEdit = useCallback(() => {
    // localStorage.setItem("prompt", message);
    handleModal(false);

    let prompt = messageList;

    let find_prompt = prompt.find((o) => o.date === val.date);
    find_prompt.value = message;
    console.log(find_prompt, prompt);

    save(messageList, "prompt");
    setMessage("");
    showNotification("success", "Successfully data updated!");
  }, [handleModal, messageList, message]);

  const handleCancel = useCallback(()=>{
    setMessage("");
    handleModal(false)
  }, [handleModal, messageList, message])

  return (
    <CustomModal
      title="Set prompt"
      visible={open}
      okButtonText={"Submit"}
      /* onOk={handleOk} */
      onCancel={handleCancel}
      footer={[
        <>
          {/* <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>
          <Button key="back" onClick={() => handleModal(false)}>
            Cancel
          </Button> */}

          <Row className="p10">
            <Button
              className="new_button"
              type="primary"
              size="large"
              onClick={type === "edit" ? handleEdit : handleOk}
            >
              {type === "edit" ? "Save" : "Add"}
            </Button>
            <Button
              className="new_button"
              type="primary-outline"
              onClick={handleCancel}
              size="large"
            >
              Cancel
            </Button>
          </Row>
        </>,
      ]}
    >
      <Col className="mb10">
        <Input.TextArea
          autoSize={{ minRows: 12, maxRows: 24 }}
          size="large"
          type="text"
          value={message}
          placeholder="Improve your communication by adding a  custom message when no result is found in your database"
          onChange={handleChange}
        />
      </Col>
    </CustomModal>
  );
};

export default SetTrainingPromptModal;
