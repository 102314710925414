import React from "react";
import { Card, Col, Row, Steps } from "antd";

import "../OpenAi.scss";

const { Step } = Steps;

const GenerateContentContainer = ({
  containerClassName = "",
  showSteps = true,
  steps = [],
  currentStep = 0,
  ...props
}) => {
  return (
    <Row
      className={`open-ai content-body open-ai-generate-content-container justify-self-center ${containerClassName}`}
      justify="center"
    >
      <Col className="fw" xxl={20}>
        {showSteps && steps.length > 1 && (
          <>
            <Col className="steps-container steps-container-horizontal mb-10">
              <Steps current={currentStep}>
                {steps.map((step, i) => (
                  <Step title={step.name} progressDot={() => i + 1} />
                ))}
              </Steps>
            </Col>

            <Col className="steps-container steps-container-vertical mb-10">
              <Steps current={currentStep} labelPlacement="vertical">
                {steps.map((step, i) => (
                  <Step title={step.name} progressDot={() => i + 1} />
                ))}
              </Steps>
            </Col>
          </>
        )}

        {props.header && <Col className="mb-10">{props.header}</Col>}

        <Card
          className="--custom-card-container"
          title={steps?.[currentStep]?.header}
        >
          {props.children}
        </Card>
      </Col>
    </Row>
  );
};

export default React.memo(GenerateContentContainer);
