const constants = Object.freeze({
  // User access token key
  ACCESS_TOKEN: "email",

  // Actions
  ACTION_TYPES: {
    LOGOUT: "LOGOUT",
  },

  URL_REGEX:
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,

    FILTER_TYPE_DATE: {
      All: { name: "All", _id: "all_data" },
      TODAY: { name: "Today", _id: "today" },
      YESTERDAY: { name: "Yesterday", _id: "yesterday" },
      CUSTOM_DATE: { name: "Custom Date", _id: "custom_date" },
  },
    
    FILTER_TYPE_STATUS: {
      All: { name: "All", _id: "all_data" },
      Active: { name: "Active", _id: "active" },
      Inactive: { name: "Inactive", _id: "inactive" },
    },
});

export default constants;
