import { Button, Col, Input, Modal, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

const SetMessageModal = ({ open, handleModal, save }) => {
    const [message, setMessage] = useState( "");
    const prevProp = useRef();
    useEffect(() => {
        if (!prevProp?.current?.open && open) {
            const custom_message = localStorage.getItem("custom_message") ?? "";
            setMessage(custom_message);
        }

        return () => {
            prevProp.current = { open };
        };
    }, [open]);

    const handleChange = useCallback((e) => {
        let value = e?.target?.value ?? e;

        setMessage(value);
    }, []);

    const handleOk = useCallback(() => {
        localStorage.setItem("custom_message", message);
        handleModal(false);
        save(message, "custom_message")
    }, [handleModal, message]);

    return (
        <Modal
            title="Set custom message"
            open={open}
            onOk={handleOk}
            closeIcon={<></>}
            footer={[
                <>
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Save
                    </Button>
                    <Button key="back" onClick={() => handleModal(false)}>
                        Cancel
                    </Button>
                </>,
            ]}
        >
            <Col className="mb10">
                <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    size="large"
                    type="text"
                    value={message}
                    placeholder="Improve your communication by adding a  custom message when no result is found in your database"
                    onChange={handleChange}
                />
            </Col>
        </Modal>
    );
};

export default SetMessageModal;
