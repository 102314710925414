import React, { useCallback, useEffect, useRef, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import AppUrl from "../config/appUrl";
import OpenAiChatItem from "../openAiChat/components/OpenAiChatItem";
import { showNotification } from "../utils/commonFunctions";
import { Button, Col, Divider, Input, Row, Avatar, Space } from "antd";
import {
  LeftOutlined,
  SendOutlined,
  QuestionCircleOutlined,
  MailOutlined,
  ArrowLeftOutlined,
  PlusCircleOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";
import routes from "../routes_mapper";
import Loader from "../components/loader/Loader";

const LaunchBot = () => {
  const location = useLocation()
 // console.log("--------", location?.state)
  const messageRefs = useRef([]);
  const navigate = useNavigate();

  const [state, setState] = useState({
    message: "",
    text: "",
    showDefaultResponse: false,
  });

  const [fileList, setFileList] = useState([]);
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: `Hello 👋, I'm the ${location?.state?.name} bot, ready to help! 😊 Feel free to share your inquiry with me.`,
      loading: false,
    }
  ]);
  const [textFromFiles, setTextFromFiles] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [hideChatInput, setHideChatInput] = useState(false);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [loadingData, setLoadingData] = useState(true)

 // console.log("questions", questions);
  const email = localStorage.getItem("email");
  useEffect(() => {
    const userQueries = messages.filter((item) => item.role === "user");
    const otherResponses = messages.filter((item) => item.role !== "user");

    setQuestions(userQueries.map((item) => item.content));
    setAnswers(otherResponses.map((item) => item.content));
  }, [messages]);

  const maxLength = Math.min(questions.length, answers.length);

  const handleSendEmail = async () => {
    /* try {
      const response = await axios.post(AppUrl.SEND_MAIL, {
        email: email,
        messages: [...Array(maxLength)].map((o,i)=>{
          return {
            user:questions[i],
            bot:answers[i]
          }
        }),
      });
      message.success('Successfully mail sent');
       } catch (error) {
        message.error('Failed to send mail');
       } */
  };

  useEffect(() => {
    // Your code here
    const email = localStorage.getItem("email");

    axios
      .post(AppUrl.GET_FILE, { email: email })
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          localStorage.setItem("custom_message", res.data?.data?.error_message);
          localStorage.setItem("prompt", res.data?.data?.prompt);
          if (localStorage.getItem("name") !== res.data?.data?.name) {
            localStorage.setItem("name", res.data?.data?.name);
          }
          setState((preState) => ({
            ...preState,
            text: res.data?.data?.message,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to fatch file data");
      });
  }, []);
  const saveData = (customMessage, type) => {
    const email = localStorage.getItem("email");
    setState((preState) => ({
      ...preState,
      loading: true,
    }));
    let payload = { email: email, text: state.text.trim() };
    if (type === "custom_message" && typeof customMessage === "string") {
      payload.customMessage = customMessage;
    }
    if (type === "prompt" && typeof customMessage === "string") {
      payload.prompt = customMessage;
    }
    axios
      .post(AppUrl.SAVE_FILE, payload)
      .then((res) => {
        if (res.data.success) {
          setState((preState) => ({
            ...preState,
            loading: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showNotification("error", "Failed to save file data");
      });
  };

  /*   const beforeUpload = useCallback((file) => {
    const supportedFileType = ["text/plain"];

    let isAcceptable = supportedFileType.includes(file?.type);
    if (!isAcceptable) {
      showNotification("error", "Please upload .txt file only");
    }
    return isAcceptable || Upload.LIST_IGNORE;
  }, []);

  const updateImageUpload = useCallback(({ file }) => {
    const fileId = file?._id || file?.uid;
    if (file.type === "text/plain") {
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileContent = e.target.result;
        setTextFromFiles((preTextFile) => ({
          ...preTextFile,
          [fileId]: fileContent,
        }));
      };

      reader.readAsText(file);
    }
    setFileList((preState) => [...preState, file]);
  }, []); */

  /*   const onRemoveImage = useCallback((file) => {
    const fileId = file?._id || file?.uid;

    setTextFromFiles((preFile) => {
      delete preFile[fileId];
      return preFile;
    });

    setFileList((preState) =>
      preState.filter((p) => (p?._id || p?.uid) !== fileId)
    );
  }, []); */

  /*   useEffect(() => {
    let text = Object.values(textFromFiles).join("\n");
    setState((preState) => ({
      ...preState,
      text,
    }));
  }, [textFromFiles, fileList.length]); */

  const onSend = useCallback(() => {
    const newMessages = messages;
    let message = state.message.trim();
    // const text = state.text.trim();
    if (!message) return;
    // if (!text) return;


    newMessages.push({
      role: "user",
      content: message,
    });

    const payload = {
      question: message,
      // text: text,
      email: location?.state?.email //|| localStorage.getItem("email"),
      // customMessage: custom_message
    };

    newMessages.push({
      role: "assistant",
      content: "typing...",
      loading: true,
    });

    setMessages(newMessages);
    setState((prevState) => ({ ...prevState, message: "", loading: true }));

    const lastMessageIndex = messages.length - 1;
    setTimeout(
      () =>
        messageRefs?.current?.[lastMessageIndex]?.scrollIntoView?.({
          behavior: "smooth",
        }),
      100
    );

    axios({
      method: "POST",
      url: `${AppUrl.FILES}custom`,
      data: payload,

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjYyZTIzYzA1ZDkzZDFjNmMzZDRiY2MwYSIsImVtYWlsIjoic29ub28uZ3VwdGFAaWJpc20uY29tIiwicm9sZV9pZCI6ImFkbWluIiwibmFtZSI6bnVsbH0sImlhdCI6MTY4NzM0ODIwNywiZXhwIjoxNjg3OTUzMDA3fQ.Po1HQFCga0pzAAj6aIkFL8nBwVTkbXR2hwQdEhATHLM",
      },
    })
      .then((response) => {
        const resData = response.data;
        // console.log(resData);

        if (!resData?.text) {
          setMessages((prevMessages) => {
            const lastMesssage = prevMessages.pop();
            console.log("remove message: ", lastMesssage);

            return [...prevMessages];
          });
        } else {
          const newMessage = {};
          let content = resData?.text;
          while (content.startsWith("\n")) {
            content = content.replace("\n", "");
          }
          newMessage.content = content;

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );

          setMessages((prevMessages) => {
            prevMessages.pop();
            console.log("remove message: ");
            return [...prevMessages, newMessage];
          });

          setTimeout(
            () =>
              messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
                behavior: "smooth",
              }),
            100
          );
          console.log("message--->", message);
          // if (
          //   message.toLowerCase() !== "hi" &&
          //   message.toLowerCase() !== "hello" &&
          //   message.toLowerCase() !== "hola"
          // ) {
            // setMessages((prevMessages) => {
            //   return [
            //     ...prevMessages,
            //     {
            //       role: "assistant",
            //       content: "Did your find this information helpful ?",
            //       loading: false,
            //     },
            //     {
            //       type: "acknowledge",
            //     },
            //   ];
            // });
          // }
        }

        setState((prevState) => ({ ...prevState, loading: false }));
      })
      .catch((err) => {
        console.log("chat err: ", err);
        showNotification("error", "Something went wrong");
        setMessages((prevMessages) => {
          const lastMesssage = prevMessages.pop();
          console.log("remove message: ", lastMesssage);

          return [...prevMessages];
        });
        setState((prevState) => ({ ...prevState, loading: false }));
      });
  }, [state.message, state.text, messages]);

  const handleChange = useCallback(
    (name) => (e) => {
      let value = e?.target?.value ?? e;

      setState((prevState) => {
        prevState[name] = value;
        return { ...prevState };
      });
    },
    []
  );

  const handleDefaultResponseOne = () => {
    setLoadingOne(true);
    setLoadingTwo(false);
    const custom_message =
      "Great to hear that! If you have any more questions or need further assistance, feel free to reach out.";
    const newMessages = messages;
    newMessages.push({
      role: "assistant",
      content: custom_message,
      loading: false,
    });

    setMessages(newMessages);
  };

  const handleDefaultResponseTwo = () => {
    setLoadingOne(false);
    setLoadingTwo(true);
    const newMessages = messages;
    newMessages.push({
      role: "assistant",
      content: `We apologize for any inconvenience caused. We strive to improve our responses every day to better address your inquiries.`,
      loading: false,
    });
    newMessages.push({
      role: "assistant",
      content: `For more details, reach out to us via email or phone.`,
      loading: false,
    });
    newMessages.push({
      role: "assistant",
      content: `May we have your email address for assistance?`,
      loading: false,
    });
    newMessages.push({
      type: "email",
    });

    setMessages(newMessages);
    setHideChatInput(true);
  };

  const onSendNegativeMail = () => {
    setMessages((prevMessages) => {
      const lastMessageIndex = messages.length - 1;

      setTimeout(
        () =>
          messageRefs?.current?.[lastMessageIndex + 1]?.scrollIntoView?.({
            behavior: "smooth",
          }),
        100
      );

      prevMessages.push({
        role: "assistant",
        content: `Thanks for providing us email, Our team will contact you soon.`,
        loading: false,
      });
      return [...prevMessages];
    });
  };

    const messageBoxRender = () => {
      

    if (messages.length === 0) {
      return (
        <>
          {/*<Row className="chat-item-container">*/}
          {/*  <Col>*/}
          {/*    <Avatar*/}
          {/*      size={40}*/}
          {/*      shape={"circle"}*/}
          {/*      style={{ backgroundColor: "rgb(255, 161, 108)" }}*/}
          {/*    >*/}
          {/*      {"Bot"}*/}
          {/*    </Avatar>*/}
          {/*  </Col>*/}

          {/*  <Col className="chat-details-container">*/}
          {/*    <Row*/}
          {/*      className="user-details"*/}
          {/*      align="middle"*/}
          {/*      justify="space-between"*/}
          {/*    >*/}
          {/*      /!* <span className="name">{createdBy.name ?? "Bot"}</span> *!/*/}
          {/*      <span className="name">{location?.state?.name}</span>*/}
          {/*      <span>*/}
          {/*        Hello 👋, I'm the {location?.state?.name} bot, ready to help! 😊 Feel free to*/}
          {/*        share your inquiry with me.*/}
          {/*      </span>*/}
          {/*    </Row>*/}
          {/*  </Col>*/}
          {/*</Row>*/}



          {/*  <Row
           className="h-full"
           align="middle"
           justify="center"
       >
         <img
             className="h-64 w-full"
             src="/img/empty-ai-chat.svg"
             alt="Writely Bot"
             style={{"width":"300px"}}
         />
       </Row> */}
        </>
      );
    } else {
      return messages?.map?.((message, i) => {
        // if (message.type === "acknowledge") {
        //   return (
        //     <Row style={{ marginLeft: 50 }}>
        //       <Button
        //         className="m-1 active"
        //         size="large"
        //         type={loadingOne ? "default" : "primary"}
        //         disabled={loadingOne || loadingTwo}
        //         onClick={handleDefaultResponseOne}
        //       >
        //         Yes 👍
        //       </Button>
        //       <Button
        //         className="m-1"
        //         type={loadingTwo ? "default" : "primary"}
        //         size="large"
        //         disabled={loadingOne || loadingTwo}
        //         onClick={handleDefaultResponseTwo}
        //       >
        //         No 👎
        //       </Button>
        //     </Row>
        //   );
        // } else if (message.type === "email") {
        //   return (
        //     <Row align="middle" wrap={false} style={{ marginLeft: 50 }}>
        //       <Col className="grow">
        //         <Input
        //           className="chat-input"
        //           value={state.email}
        //           placeholder={"Please provide your email address"}
        //           onChange={handleChange("email")}
        //           autoSize={{ minRows: 1, maxRows: 4 }}
        //           onPressEnter={(e) => {
        //             if (e.key === "Enter" && !e.shiftKey) {
        //               onSendNegativeMail();
        //             }
        //           }}
        //           disabled={state.loading}
        //           size="large"
        //         />
        //       </Col>
        //       <Button
        //         className="text-2xl btn-color"
        //         type="text"
        //         onClick={onSendNegativeMail}
        //         // disabled={!state.message.trim()}
        //         // loading={state.loading}
        //         size="large"
        //         icon={<SendOutlined className="text-3xl" />}
        //       />
        //     </Row>
        //   );
        // } else {
          return (
            <div key={i} ref={(mRef) => (messageRefs.current[i] = mRef)}>
              <OpenAiChatItem key={i} message={message} state={location?.state} />
              <Row justify="end" align="">
                {message.content ===
                  "Sorry we could not find detail about your query. Please contact our support team at help@oben.com | +91 1232123212." && (
                  <Col
                    style={{
                      marginRight: "20px",
                      marginTop: "-110px",
                    }}
                  >
                    <Button>
                      <MailOutlined onClick={handleSendEmail} />
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          );
        // }
      });
    }
    };

    useEffect(() => { 
        setTimeout(() => {
            setLoadingData(false)
        },2000)
    },[])
    
    if (loadingData) { 
        return <Loader loading={loadingData} />
    }

  return (
    <div className="open-ai-chat bg-white-cb custom-l-cb">
      <Row justify={"space-between"} align={"middle"} className="mb20 pos_rel">
        <Col align="start">
          <div className="back-cb" onClick={()=>navigate(routes.ROOT)} >
            <ArrowLeftOutlined />
          </div>
        </Col>
        <Col xs={24} md={12} align="end">
          <Row>
            <Col xs={24} align="end">
              <Space>
                <Button
                  type="primary"
                  icon={<DeploymentUnitOutlined />}
                  size="large"
                                  style={{ minWidth: "160px" }}
                                  onClick={()=>navigate(routes.TrainingBot, { replace: false, state: location?.state })}
                >
                  {" "}
                  Train{" "}
                </Button>

                <Button
                  type="primary"
                  icon={<QuestionCircleOutlined />}
                  size="large"
                                  style={{ minWidth: "160px" }}
                                  onClick={()=>navigate(routes.QUERYDETAILS, { replace: false, state: location?.state })}
                >
                  {" "}
                  Question Log
                </Button>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="content-container">
        <Row className="fw h-full" justify="space-between">
          <Col className="--left-container" xs={24}>
            <div className="center-frame">
              <div className="tab-frame-cb">
                <div className="tab-inner-frame-cb">
                  <div className="tab-inner-frame-top">
                    {/* <Col className="title-container mb15">
                      <Row align="middle">
                        <LeftOutlined
                          className="text-xl mr-2"
                          onClick={onGoBack}
                        />

                        <Col className="title">
                          Fine-tune ChatGPT via File Upload
                        </Col>
                      </Row>
                    </Col> */}

                    <Col
                      className=""
                      style={{ height: "475px", overflowY: "auto" }}
                    >
                      {/* chat container start */}
                      <Col className="chat-container h-4/6">
                        <Col className="message-list-container">
                          {messageBoxRender()}
                        </Col>
                      </Col>
                      {/* chat container end */}
                    </Col>

                    {!hideChatInput && (
                      <Col className="mt20">
                        {/* chat input container end */}
                        <Row align="middle" wrap={false}>
                          <Col className="grow">
                            <Input
                              className="chat-input"
                              value={state.message}
                              placeholder={
                                loadingTwo
                                  ? "Please provide your email address"
                                  : "Enter your prompt"
                              }
                              onChange={handleChange("message")}
                              autosize={{ minRows: 1, maxRows: 4 }}
                              onPressEnter={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                  onSend();
                                }
                              }}
                              disabled={state.loading}
                              size="large"
                            />
                          </Col>
                          <Button
                            className="text-2xl btn-color"
                            type="text"
                            onClick={onSend}
                            disabled={!state.message.trim()}
                            loading={state.loading}
                            size="large"
                            icon={<SendOutlined className="text-3xl" />}
                          />
                        </Row>
                        {/* chat input container end */}
                      </Col>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={1}
            xl={1}
            xxl={1}
            className="h-5/6"
            align="center"
          >
            <Divider type="vertical" />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default LaunchBot;
