import React from "react";
import loaderImg from "../../images/rocket.gif";

const Loader = ({ loading = false }) => {
    return ( 
        <>
            <div className={`loader ${loading ? "active" : ""}`}>
                <div className="center-loader">
                    <span>
                        <img src={loaderImg} alt="loader" />
                    </span>
                    <p>Please wait! We are launching your bot!</p>
                </div>
          </div>
        </>
     );
}
 
export default Loader;